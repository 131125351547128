import React from 'react';
import { Timeline } from 'antd';
import moment from 'moment';
import './SessionTimeline.css';

const SessionTimeline = ({ sessions, onSessionSelect, activeSession, shouldGlow, hubLoadComplete }) => {
  const getCustomDot = (index) => {
    if (index === 0 && shouldGlow) {
      return (
        <div
          className="red-glow"
          style={{
            height: '10px',
            width: '10px',
            borderRadius: '50%',
            backgroundColor: 'red',
          }}
        />
      );
    }
    return null;
  };

  if (hubLoadComplete && (!sessions || sessions.length === 0)) {
    return (
      <div className="empty-timeline-message">
        There are no sessions for this hub. To create a new session, connect your hub to the Internet and press the plus icon above, or simply press start.
      </div>
    );
  }

  return (
    <div className="session-timeline-wrapper">
      <div className="session-timeline-container">
        <Timeline>
          {sessions && sessions.length > 0 ? (
            sessions.map((session, index) => {
              const isActiveSession = session === activeSession;
              return (
                <Timeline.Item
                  key={session}
                  dot={getCustomDot(index)}
                  color="blue"
                  onClick={() => onSessionSelect(session)}
                  className="timeline-item"
                >
                  <div
                    className={`session-item ${isActiveSession ? 'active' : ''}`}
                  >
                    {typeof session === 'string'
                      ? moment(session, 'YYYYMMDDHHmmSS').format('MM/DD/YYYY HH:mm:SS')
                      : moment(session).format('MM/DD/YYYY HH:mm:SS')}
                  </div>
                </Timeline.Item>
              );
            })
          ) : (
            <p className="loading-message">Loading...</p>
          )}
        </Timeline>
      </div>
    </div>
  );
};

export default SessionTimeline;