// ApiService.js
import { messageBroker } from './messageBroker';

export class WebSocketManager {
  constructor() {
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 10;
    this.url = ""
    this.isLocal = false
  }

  async connect(isLocal) {
    return new Promise((resolve, reject) => {
      const LOCAL_WS_URL = 'ws://localhost:3030';
      const REMOTE_WS_URL = 'wss://intelligentsensinglabs.com';
  
      if (isLocal !== this.isLocal) {
        this.isLocal = isLocal;
        this.url = isLocal ? LOCAL_WS_URL : REMOTE_WS_URL;
      }

      if (!this.url) {
        this.url = this.isLocal ? LOCAL_WS_URL : REMOTE_WS_URL;
      }

      // If a connection attempt is ongoing or already open, handle appropriately
      if (this.ws) {
        switch (this.ws.readyState) {
          case WebSocket.CONNECTING:
            console.log('WebSocket is currently connecting.');
            this.ws.addEventListener('open', () => resolve(this.ws), { once: true });
            this.ws.addEventListener('error', reject, { once: true });
            return;
          case WebSocket.OPEN:
            resolve(this.ws);
            return;
          default:
            console.log("Websocket is closing.")
            return;
        }
      }
  
      // Proceed to create a new WebSocket connection
      this.ws = new WebSocket(this.url);
      console.log(`Connecting to ${this.url}`);
  
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const topic = data.topic;
        messageBroker.emit(topic, data);
      };
  
      this.ws.onopen = () => {
        console.log('WebSocket connection opened.');
        this.reconnectAttempts = 0;
        resolve(this.ws);
      };
  
      this.ws.onerror = (error) => {
        console.error('WebSocket connection error:', error);
        reject(error);
      };
  
      this.ws.onclose = (event) => {
        console.log(`WebSocket connection closed with code ${event.code} and reason: ${event.reason}`);
        if (this.shouldReconnect(event)) {
          this.reconnect();
        }
      };
    });
  }
  
  shouldReconnect(event) {
    // Determine whether we should attempt to reconnect based on the event code
    return ![1000, 1001].includes(event.code); // Normal closure should not reconnect
  }

  reconnect() {
    const maxDelay = 30000; // Maximum delay of 30 seconds
    const delay = Math.min(maxDelay, Math.pow(2, this.reconnectAttempts) * 1000);
    const jitter = Math.random() * 1000; // Add some randomness to avoid thundering herd effect
    setTimeout(() => {
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++;
        this.connect(this.isLocal).catch(console.error);
      }
    }, delay + jitter);
  }

  async send(message) {
    await this.connect(this.isLocal); // Make sure we're connected
    if (this.ws.readyState === WebSocket.OPEN) {
      this.ws.send(message);
    } else {
      console.error("WebSocket is not open. Unable to send message.");
    }
  }
}

export const wsManager = new WebSocketManager();

// Websocket message handlers
export const getSessions = async (hub, client) => {
  const payload = {
    topic: 'sessions',
    sender: {
      id: client,
      type: "client",
    },
    recipient: {
      id: hub,
      type: "hub"
    }
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
};

export const downloadData = async (hub, client, session, document) => {
    const payload = {
      topic: 'req',
      sender: {
        id: client,
        type: "client"
      },
      recipient: {
        id: hub,
        type: "hub"
      },
      session: session,
      document: document
    };
    const message = JSON.stringify(payload);
    wsManager.send(message);
}

export const subscribe = async (hub, client) => {
  const payload = {
    topic: 'sub',
    sender: {
      id: client,
      type: "client"
    },
    recipient: {
      id: hub,
      type: "hub"
    },
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
}

export const unsubscribe = async (hub, client) => {
  const payload = {
    topic: 'unsub',
    sender: {
      id: client,
      type: "client"
    },
    recipient: {
      id: hub,
      type: "hub"
    },
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
}

export const getStatus = async (hub,client) => {
  const payload = {  
    topic: 'status_req',
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    }
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
}

export const startRecording = async (hub, client) => {
  const payload = {
    topic: 'command',
    command: 'start',
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    }
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
}

export const stopRecording = async (hub, client) => {
  const payload = {
    topic:'command',
    command: 'stop',
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    }
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
}

export const newSession = async (hub, client) => {
  const payload = {
    topic:'command',
    command: 'new_session',
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    }
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
  console.log ("Sent new session request: ")
  console.log ( message )
}

export const deleteSession = async (hub, client, session) => {
  const payload = {
    topic:'delete_session',
    session: session,
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    }
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
  console.log ("Sent delete session request to server: ")
  console.log ( message )
}

export const setConfig = async (hub, client, session, config) => {
  const payload = {
    topic:'set_config',
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    },
    session: session,
    config: config
  };
  const message = JSON.stringify(payload);
  wsManager.send(message);
}

export const setSensorPrefs = async (hub, client, config) => {
  const payload = {
    topic: 'command',
    command: 'settings',
    sender: { 
      id: client, 
      type: 'client' 
    },
    recipient: { 
      id: hub, 
      type: 'hub' 
    },
    config: config
  };
  console.log("Sent a settings command to the hub: ", payload)
  const message = JSON.stringify(payload);
  wsManager.send(message);
}