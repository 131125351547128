import React, { useState, useEffect, useRef } from 'react';
import { Card, Col, Row, Divider, Select, Dropdown, Menu, Button, Modal } from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';
import ResponsiveLineChart from './ResponsiveLineChart';
import SessionTimeline from './SessionTimeline';
import { wsManager, getSessions, downloadData, subscribe, unsubscribe, getStatus, startRecording, stopRecording, newSession, deleteSession, setConfig, setSensorPrefs } from './ApiService';
import { messageBroker } from './messageBroker';
import EventManager from './EventManager';
import SensorManager from './SensorManager';
import './Dashboard.css';

// Destructuring features from antd
const { Option } = Select;
const { confirm } = Modal;

const Dashboard = ({ userData, isLocal }) => {
  const [wsConnected, setWsConnected] = useState(false);
  const [sources, setSources] = useState(null);
  const [hubId, setHubId] = useState(null);
  const [hubLoadComplete, setHubLoadComplete] = useState(false);
  const [sessions, setSessions] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [latestSession, setLatestSession] = useState(null);
  const [sessionLoadComplete, setSessionLoadComplete] = useState(false);
  const [sessionsConfig, setSessionsConfig] = useState({});
  const [currentSessionConfig, setCurrentSessionConfig] = useState({});
  const [latestDocument, setLatestDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [hubStatuses, setHubStatuses] = useState({});
  const [hubControlsActive, setHubControlsActive] = useState(false);
  const [activeHubIsRecording, setActiveHubIsRecording] = useState(false);
  const [pendingHubCommand, setPendingHubCommand] = useState(false);
  const [clientId, setClientId] = useState (false);
  const [renamedKeys, setRenamedKeys] = useState({});
  const [connectionTimestamp, setConnectionTimestamp] = useState(Date.now());
  const [activeSensorInfo, setActiveSensorInfo] = useState(null);

  const offlineTimers = useRef({});
  
  // Assign clientId
  const generateClientId = () => {
    const generateRandomString = (length) => {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    };
  
    const part1 = generateRandomString(6);
    const part2 = generateRandomString(6);
    const part3 = generateRandomString(6);
  
    return `${part1}-${part2}-${part3}`;
  }
  useEffect(() => {
    let generatedId = generateClientId()
    setClientId(generatedId)
  },[])

  // Manage data sources
  useEffect(() => {
    if (userData && userData.sensors) {
      setSources(userData.sensors);
    }
  }, [userData, sources]);

  // Manage hub
  const handleHubChange = (value) => {
    setHubLoadComplete(false);
    setActiveHubIsRecording(false);
    setRenamedKeys({});
    setSessions(null);
    setHubId(value);
    getStatus(value, clientId);
  };
  useEffect(() => {
    if (clientId && sources && sources.length > 0 && wsConnected) {
      sources.forEach((source) => {
        getStatus(source, clientId);
      });
      // Set last hub default hub
      handleHubChange(sources[sources.length-1])
    };
  }, [sources, clientId, wsConnected]);

  // Manange data recording
  const handleStartStopButtonClick = async () => {
    console.log('Pressed start/stop button')
    const currentStatus = hubStatuses[hubId];
    switch (currentStatus) {
      case "ready":
        console.log("Starting recording...")
        startRecording(hubId, clientId)
        setPendingHubCommand(true)
        break;
      case "recording":
        console.log("Stopping recording...")
        stopRecording(hubId, clientId)
        setPendingHubCommand(true)
        break;
      default:
        break;
    }
  };

  // Manage sessions
  useEffect(() => {
    const handleSessions = (data) => {
      const { sessions, config } = data;

      console.log("Got sessions from server: ", sessions);

      setSessions(sessions);
      const newCurrentSession = sessions && sessions.length > 0 ? sessions[0] : null;
      setLatestSession(newCurrentSession);

      if (config) {
        setSessionsConfig(config);
      }
      if (config[newCurrentSession]){
        setCurrentSessionConfig(config[newCurrentSession]);
      }
      setHubLoadComplete(true);
    };
    messageBroker.on('sessions', handleSessions);

    // Fetch sessions upon hubId or clientId change and ensure hubLoadComplete is false to avoid repeat calls
    if (wsConnected && hubId && clientId && !hubLoadComplete) {
      getSessions(hubId, clientId);
    }

    return () => {
      messageBroker.off('sessions', handleSessions);
    };
  }, [hubId, clientId, sessionsConfig, wsConnected]);

  // Update the timestamp every time a connection is established
  useEffect(() => {
    if (wsConnected) {
      setConnectionTimestamp(Date.now()); // Unique value on each connection
      console.log("WS connected at: ", Date.now())
    }
  }, [wsConnected]);

  // Depend on this unique value to trigger session fetching
  useEffect(() => {
    if (hubId && clientId) {
      getSessions(hubId, clientId);
      console.log("Triggered getSessions via timestamp hook")
    }
  }, [hubId, clientId, connectionTimestamp]);

  // Handle switch to latest session on new latest session
  useEffect(() => {
    if (latestSession) {
      console.log("Updating current latest session to latest session");
      handleSessionSelect(latestSession);
    }
  }, [latestSession]);

  const handleSessionSelect = async (selectedSession) => {
    if (selectedSession !== currentSession) {
      setIsLoading(true);
      setSessionLoadComplete(false);
      setRenamedKeys({});
      setCurrentSession(selectedSession);
    };
  };

  // Update config for current session on session change
  useEffect(() => {
    setCurrentSessionConfig(sessionsConfig[currentSession])
  },[sessionsConfig, currentSession])

  const handleNewSession = async () => {
    await newSession(hubId, clientId)
    getSessions(hubId, clientId)
  }

  const handleDeleteSession = async () => {
    await deleteSession(hubId, clientId, currentSession);
    
    await getSessions(hubId, clientId).then(() => {
        const index = sessions.findIndex(session => session === currentSession);
        const newIndex = Math.max(0, index - 1); // Ensure the index is not negative
  
        if (sessions.length > 0) {
            handleSessionSelect(sessions[newIndex]);
        }
    });
  };

  // Manage documents
  const fetchAndSetDocuments = async (session) => {
    try {
      let latestDoc;
      let cachedData = await getCachedData(hubId, session);
      if (cachedData && cachedData.length > 0) {
        latestDoc = cachedData[cachedData.length - 1].document
      }
      if (latestDoc) {
        setLatestDocument(latestDoc) 
      } else {
        setLatestDocument(null)
      }
      setSessionLoadComplete(true);
    } catch (error) {
      console.error('Error getting latest document: ', error);
    }
  }
  useEffect(() =>{
    if (!sessionLoadComplete && currentSession) {
      fetchAndSetDocuments(currentSession);
    }
  }, [currentSession, sessionLoadComplete])

  // Manage bulk data download
  useEffect(() => {
    const handleDownloadData = async (data) => {
      const { documents } = data; 
      if (!documents.error) {
        await cacheData(hubId, documents);
        const updatedChartData = await getCachedData(hubId, currentSession);
        setChartData(updatedChartData);
      } else {
        console.error('Error catching up data from server:', documents.error);
      }
      setIsLoading(false);
    };
    messageBroker.on('data', handleDownloadData);

    return () => {
      messageBroker.off('data', handleDownloadData);
    };
  }, [hubId, currentSession]);

  const loadData = async () => {
    setIsLoading(true);
    await downloadData(hubId, clientId, currentSession, latestDocument);
    // The 'data' topic message handler (handleDownloadData) will process the response
  };

  useEffect(() => {
    if (sessionLoadComplete && currentSession) {
      loadData();
    }
  }, [currentSession, sessionLoadComplete, hubId]);

  // Manage live data updates
  const handleLiveData = async (newData) => {

    if (newData?.sender?.id === undefined) {
      return;
    }
    const hubIdFromServer = newData.sender.id;
  
    // Manage live data updates
    if (currentSession === latestSession) {
      console.log(`Received live update for ${hubIdFromServer} for session ${currentSession}:`, newData.data);

      let cachedData = await getCachedData(hubIdFromServer, currentSession);
      console.warn(cachedData)
      let cachedConfig = cachedData[0].config
      console.warn(cachedConfig)
      
      if (cachedData && cachedData.length > 0) {
        console.log("Cached data length: ", cachedData.length);
        console.log("Contents of cached data at position: ", cachedData[cachedData.length - 1]);
      
        // Check if `data` exists, if not, initialize it as an empty array
        if (!cachedData[cachedData.length - 1].data) {
          cachedData[cachedData.length - 1].data = [];
        }
      
        // Push the new data into the existing or newly created `data` array
        cachedData[cachedData.length - 1].data.push(...newData.data);
      }
      else {
        cachedData = [{
          session: newData.session,
          document: newData.document,
          data: newData.data,
        }];
      }
      
      await cacheData(hubIdFromServer, cachedData);
      setChartData(cachedData);

      // Clear old status timeout
      if (offlineTimers[hubIdFromServer]) {
        clearTimeout(offlineTimers[hubIdFromServer]);
      }

      if (hubStatuses[hubIdFromServer] !== "recording") {
        setHubStatuses((prevStatuses) => ({
          ...prevStatuses,
          [hubIdFromServer]: "recording",
        }));
      }
    
      setActiveSensorInfo(cachedConfig);
      // Set new timeout
      offlineTimers[hubIdFromServer] = setTimeout(() => {
        setHubStatuses((prevStatuses) => ({
          ...prevStatuses,
          [hubIdFromServer]: "offline",
        }));
        setActiveSensorInfo(null);
        console.log(`Timer expired, setting hub ${hubIdFromServer} status to offline and clearing config`);
      }, 15000);

    } else {
      console.log('Received live update for a non-current session. Ignoring.');
    }
  }; 
  
  useEffect(() => {
    const subscribeToLiveData = () => {
      console.log("Triggered subscribe to live data.")
      if (wsConnected && hubId && clientId && !isSubscribed) {
        subscribe(hubId, clientId);
        setIsSubscribed(true); // TO-DO: This needs to make sure sub is success
        console.log(`Subscribed to live updates for hub: ${hubId}.`);
      }
    };
    // Attempt to subscribe whenever the relevant dependencies change
    subscribeToLiveData();
  
    return () => {
      if (isSubscribed) {
        unsubscribe(hubId, clientId);
        setIsSubscribed(false);
        console.log(`Unsubscribed from live updates for hub: ${hubId}`);
      }
    };
  }, [hubId, clientId, wsConnected, isSubscribed]);

  // Messages from server
  const handleStatusReport = (report) => {
    let { sender, status, config, timestamp, session, document } = report;

    console.warn("Received report: ", report);

    const hubIdFromServer = sender.id;
    if (session != null) {
      console.warn("Setting latest session to ", session);
      setLatestSession(session);
    }

    if (document != null) {
      console.warn("Setting latest document to ", document);
      setLatestDocument(document);
    }

    if (status != null && timestamp != null) {
      let interpretedStatus;
      interpretedStatus = isHubLive(timestamp) ? status : "offline";
      if (interpretedStatus !== hubStatuses[hubIdFromServer]) {
        setHubStatuses((prevStatuses) => ({
          ...prevStatuses,
          [hubIdFromServer]: interpretedStatus,
        }));
        console.log(`After check timestamp freshneess, setting hub status to ${interpretedStatus}`);
      };
    }
        
    if (config) {
      console.log("Config in status update: ", config);
      setActiveSensorInfo(config);
      //setCurrentSessionConfig(config);
      // Add new config to sessions config list
      setSessionsConfig((prevSessionsConfig) => ({
        ...prevSessionsConfig,
        [session]: config,
      }));
    }

    if (offlineTimers[hubIdFromServer]) {
      clearTimeout(offlineTimers[hubIdFromServer]);
    }
  
    offlineTimers[hubIdFromServer] = setTimeout(() => {
      setHubStatuses((prevStatuses) => ({
        ...prevStatuses,
        [hubIdFromServer]: "offline",
      }));
      setActiveSensorInfo(null);
      console.log(`Timer expired, setting hub ${hubIdFromServer} status to offline and clearing config`);
    }, 5000);
  };

  const isHubLive = (timestamp) => {
       // Parse the timestamp string into a Date object
       let timestampDateObj;

       // Legacy timestamp is a string in format YYYYMMDDHHmmSS in EST
       if (typeof timestamp === 'string') {
         const year = parseInt(timestamp.substring(0, 4), 10);
         const month = parseInt(timestamp.substring(4, 6), 10) - 1; // Months are 0-indexed in JS
         const day = parseInt(timestamp.substring(6, 8), 10);
         const hour = parseInt(timestamp.substring(8, 10), 10);
         const minute = parseInt(timestamp.substring(10, 12), 10);
         const second = parseInt(timestamp.substring(12, 14), 10);
       
         // Create a Date object assuming EST (UTC-5)
         timestampDateObj = new Date(Date.UTC(year, month, day, hour, minute, second) + 4 * 3600);
         console.log("Got time from hub: ", timestampDateObj)
 
       } else if (typeof timestamp === 'number') {
         timestampDateObj = new Date(timestamp)
         console.log("Got time from hub: ", timestampDateObj)
       }
 
       // Get the current server time in Unix format
       const currentServerTime = Date.now();
       console.log("Server timestamp is: ", currentServerTime);
   
       // Compare the timestamps, if the difference is less than 5 seconds, update status
       const timeDiff = currentServerTime - timestampDateObj;

       if (timeDiff > 5000) {
        return false 
       } else return true
  }
  
  /*
  const handleCommandAck = (data) => {
    const { recipient, command } = data;
    const hubIdFromServer = recipient.id;

    let status;
    if ( command.status === "ack" ){
      switch (command.action) {
        case "start":
          status = "recording"
        case "stop":
          status = "ready"
        default: 
          status = hubStatuses[hubIdFromServer];
      }
    }
    
    if (hubId === hubIdFromServer) {
      console.log(`Received ${command.action} command ack for hub ${hubIdFromServer}`);
      setHubStatuses((prevStatuses) => ({
        ...prevStatuses,
        [hubIdFromServer]: status,
      }));
      setPendingHubCommand(false); // Command has been acknowledged
      };

      getSessions(hubId, clientId);
  };
  */

  // Send config updates from chart back to database
  const handleConfigUpdate = (config) => {
    console.log(`Updating config for session ${currentSession}:`)
    console.log(config)
    for (const key in config) {
      if (config[key]['name'] !== undefined) {
        const updatedRenamedKeys = {
          ...renamedKeys,
          [key]: config[key]['name']
        };
        setRenamedKeys(updatedRenamedKeys);
      }
    }
    setConfig(hubId, clientId, currentSession, config)
  }

  // Send sensor settings updates to hub
  const handleSetSensorPrefs = (config) => {
    console.log("Sending sensor setttings to hub: ", config)
    setSensorPrefs(hubId, clientId, config)
    handleConfigUpdate(config)
  }

  /*
  const handleConfigUpdateAck = (payload) => {
    const { message } = payload;
    console.log (message);
    getSessions(hubId, clientId);
  }
  */

  // Ensure listeners are registered for status reports and command acks
  useEffect(() => {
    messageBroker.on('status_report', handleStatusReport);
    //messageBroker.on('command_ack', handleCommandAck);
    //messageBroker.on('success', handleConfigUpdateAck);
    return () => {
      messageBroker.off('status_report', handleStatusReport);
      //messageBroker.off('command_ack', handleCommandAck);
      //messageBroker.off('success', handleConfigUpdateAck);
    };
  }, [wsConnected, hubId, hubStatuses, currentSessionConfig]);

  // Handle live data listener controls when it gets updated!!
  useEffect(() => {
    messageBroker.on('live_data', handleLiveData);
    return () => {
      messageBroker.off('live_data', handleLiveData);
    };
  }, [currentSession, latestSession, activeSensorInfo, hubStatuses]);

  // Function that updates the button and session rec beacon based on hubStatuses
  const updateHubControls = (id) => {
    const status = hubStatuses[id];
    switch (status) {
            case "offline":
              setHubControlsActive(false);
              setActiveHubIsRecording(false);
              setPendingHubCommand(false)
              break;
            case "ready":
              setHubControlsActive(true);
              setActiveHubIsRecording(false);
              setPendingHubCommand(false)
              break;
            case "recording":
              setHubControlsActive(true);
              setActiveHubIsRecording(true);
              setPendingHubCommand(false)
              break;
            default:
              break;
    };
  };
  
  // Effect to update active hub status when hubStatuses and hubId variable changes
  useEffect(() => {
    updateHubControls(hubId);
  },[hubId, hubStatuses])

  // Manage download
  const downloadChartData = (chartData) => {
    console.log("chart data is: ", chartData);
    const csvData = jsonToCsv(chartData);
    console.log("csv data is: ", csvData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, `${currentSession}_data.csv`);
  };
  function jsonToCsv(jsonData) {
    console.log("json data: ", jsonData)
    if (!jsonData || jsonData.length === 0) {
        return "";
    }

    let csvRows = [];
    let keys = ['timestamp'];

    const configObject = jsonData.find(item => item.config);
    console.log("Config object: ", configObject);
    if (configObject) {
        keys = keys.concat(Object.keys(configObject.config).filter(key => key !== '_id'));
        csvRows.push(keys.join(','));
    } else {
        keys = keys.concat(['sensor1', 'sensor2', 'sensor3']);
        csvRows.push(keys.join(','));
    }

    console.log("keys", keys)

    // Process each entry
    jsonData.forEach(({ session, document, data }) => {
        if (data && data.length > 0) {
            data.forEach(entry => {
                const row = keys.map(key => entry[key] ? entry[key].toString() : '').join(',');
                csvRows.push(row);
            });
        }
        if (data && data.length > 0) {
            data.forEach(entry => {
                const row = keys.map(key => entry[key] ? entry[key].toString() : '').join(',');
                csvRows.push(row);
            });
        }
    });
    let csvString = csvRows.join('\n');
    return csvString;
  };

  // Manage cache
  function openDatabase() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("SensorDataDB", 1); // "SensorDataDB" is the database name
  
      request.onupgradeneeded = (event) => {
        let db = event.target.result;
        db.createObjectStore("sensorCache", { keyPath: "hubId" }); // "sensorCache" is the store name
      };
      request.onerror = (event) => {
        reject("Database error: " + event.target.errorCode);
      };
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
    });
  }

  const cacheData = async (hubId, newData) => {
    if (!newData || newData.length === 0) {
        console.error('No new data provided to cache.');
        return;
    }
    try {
        const db = await openDatabase();
        if (!db) {
            console.error('Failed to open database.');
            return;
        }
        const transaction = db.transaction(["sensorCache"], "readwrite");
        if (!transaction) {
            console.error('Failed to create transaction.');
            db.close();
            return;
        }
        const store = transaction.objectStore("sensorCache");
        if (!store) {
            console.error('Failed to access object store.');
            db.close();
            return;
        }
        const request = store.get(hubId);
        request.onsuccess = async (event) => {
            // Safely access documents, default to empty array if not present or null
            let data = event.target.result ? event.target.result.documents || [] : [];

            // Iterate through newData
            newData.forEach(newItem => {
                const { session, document } = newItem;
                // Safely find index, considering potential undefined data
                const index = data.findIndex(item => item && item.session === session && item.document === document);

                // If document already exists, overwrite it; otherwise append it
                if (index !== -1) {
                    data[index] = newItem;
                } else {
                    data.push(newItem);
                }
            });
            // Safely update the cache
            let updateRequest = store.put({ hubId: hubId, 'documents': data });
            updateRequest.onsuccess = () => {
                console.log(`Cached data updated for hub ${hubId}.`);
            };
            updateRequest.onerror = (event) => {
                console.error('Error updating cached data:', event.target.error);
            };
        };
        request.onerror = (event) => {
            console.error('Error retrieving data from IndexedDB:', event.target.error);
        };
        transaction.oncomplete = () => {
            db.close();
        };
    } catch (error) {
        console.error('An error occurred with IndexedDB:', error);
    }
  };

  const getCachedData = async (hubId, sessionId, document) => {
    try {
        const db = await openDatabase();
        if (!db) {
            console.error('Failed to open database.');
            return [];
        }

        const transaction = db.transaction(["sensorCache"], "readonly");
        const store = transaction.objectStore("sensorCache");
        const request = store.get(hubId);

        return new Promise((resolve, reject) => {
            request.onsuccess = (event) => {
                // Access the documents safely, default to empty array if not present
                const result = event.target.result;
                const cachedData = result ? result.documents || [] : [];
                
                if (sessionId && !document) {
                    // Return items matching the sessionId
                    const filteredData = cachedData.filter(item => item && item.session === sessionId);
                    resolve(filteredData);
                } else if (sessionId && document) {
                    // Return item matching the sessionId and document
                    const matchingItem = cachedData.find(item => item && item.session === sessionId && item.document === document);
                    resolve(matchingItem ? [matchingItem] : []);
                } else {
                    console.log("Invalid cache request")
                    // Invalid parameters, resolve with an empty array
                    resolve([]);
                }
            };
            request.onerror = (event) => {
                console.error('Error retrieving data from IndexedDB:', event.target.error);
                reject(event.target.error);
            };
            transaction.oncomplete = () => {
                db.close();
            };
        });
    } catch (error) {
        console.error('An error occurred with IndexedDB:', error);
        return [];
    }
  };
  const clearCache = async (hubId) => {
    try {
      const db = await openDatabase();
      if (!db) {
        console.error('Failed to open database.');
        alert('Failed to open database. Cache not cleared.');
        return;
      }
      const transaction = db.transaction(["sensorCache"], "readwrite");
      const store = transaction.objectStore("sensorCache");
      const request = store.delete(hubId);
      request.onsuccess = () => {
        console.log(`Cache cleared for hub ${hubId}.`);
        alert(`Cache cleared for device: ${hubId}`);
      };
      request.onerror = (event) => {
        console.error('Error clearing cache:', event.target.error);
        alert('Error clearing cache. Please try again.');
      };
      transaction.oncomplete = () => {
        db.close();
      };
    } catch (error) {
      console.error('An error occurred with IndexedDB:', error);
      alert('An error occurred with IndexedDB. Cache not cleared.');
    }
  };

  // Manage websocket connection
  useEffect(() => {
    const connectWebSocket = async () => {
      if (!wsConnected) {
        try {
          await wsManager.connect(isLocal);
          if (wsManager.ws) {
            setWsConnected(true);
          }
        } catch (error) {
          console.error('WebSocket connection failed:', error);
        }
      }
    };
  
    connectWebSocket();
  }, [isLocal, wsConnected]);
  
  useEffect(() => {
    const onClose = () => {
      console.log('WebSocket connection closed.');
      setWsConnected(false);
      setHubLoadComplete(false);
    };
  
    wsManager.ws?.addEventListener('close', onClose);
  
    return () => wsManager.ws?.removeEventListener('close', onClose);
  }, [wsConnected]);

  const killWebocket = () => {
    console.log("Killing websocket...")
    wsManager.ws?.close()
  }
  
  const showDeleteConfirm = () => {
    const modal = confirm({
      title: 'Delete this session?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'Cancel',
      centered: true,
      footer: (
        <div style={{ textAlign: 'center' }}>
          <Button
            onClick={() => {
              modal.destroy();
            }}
            className="no-cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDeleteSession()
              modal.destroy();
            }}
            type="danger"
            className="yes-delete-button"
          >
            Yes, delete it
          </Button>
        </div>
      )
    });
  };

  // Elipses menu options
  const menu = (
    <Menu> 
      <Menu.Item key="1" onClick={() => downloadChartData(chartData)}>
        Download Data
      </Menu.Item>
      <Menu.Item key="2" onClick={() => clearCache(hubId)}>
        Clear Cache
      </Menu.Item>
      <Menu.Item key="3" onClick={showDeleteConfirm}>
        Delete Session
      </Menu.Item>
      <Menu.Item key="4" onClick={killWebocket}>
        Reload Chart
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="dashboard" style={{ padding: '20px' }}>
      <Row gutter={24}>
        <Col span={24}>
          <Card className="welcome-card">
            <h2 className="welcome-heading">Thank you for your interest in Sense One.</h2>
            <p>We have your name and e-mail, and will contact you when our beta hardware is ready. In the meantime, feel free to visit this page to see how the dashboard is evolving. </p>
            <p>Please expect some bugs and glitches as we build the software.</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col lg={18} xs={24}>
          <Card className="welcome-card">
            <Row gutter={16} className="hub-container">
              <Col lg={9} xs={24} style={{marginBottom: '10px', marginLeft: '9px'}}>
                <h2 className="welcome-heading">Hub</h2>
                <Select
                  value={hubId}
                  style={{ width: '90%', marginBottom: '10px'}}
                  onChange={handleHubChange}>
                  {sources && sources.map(source => (
                    <Option key={source} value={source}>{source}</Option>
                  ))}
                </Select>
              </Col>

              <Divider type="vertical" style={{height: 'auto'}}/>
        
              { hubControlsActive ?
                (<Col lg={10} xs={24} style={{ paddingLeft: '16px', marginBottom: '16px' }}>
                  <SensorManager activeSensorInfo={activeSensorInfo} onSetSensorPrefs={handleSetSensorPrefs} />
                </Col>) : (<div/>)
              }

              <Divider type="vertical" style={{height: 'auto'}}/>
            
              <Col lg={3} xs={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Row>
                  {hubControlsActive ? (
                    <button className='action-btn' onClick={handleStartStopButtonClick}>
                      {pendingHubCommand ? (
                        <div className="loader-button"></div>
                      ) : (
                        <div>{activeHubIsRecording ? 'Stop' : 'Start'}</div>
                      )}
                    </button>
                  ) : (
                    <button className='greyed-out-btn'>
                      Offline
                    </button>
                  )}
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={6} xs={24} style={{ height: "100%" }}>
          <Card className="welcome-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
            <EventManager />
          </Card>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} lg={6}>
          <Card className="dash-card">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <h2>Sessions</h2>
              {hubControlsActive ? 
                (
                  <Button 
                    icon={<PlusOutlined />}
                    onClick={handleNewSession} 
                    style={{
                      background: '#f0f0f0',
                      borderRadius: '8px', 
                      width: '32px', 
                      height: '32px', 
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      boxShadow: '0 2px 6px #f0f0f0',
                      color: '#595959'
                    }}
                  />   
                ) : (<div/>)
              }
            </div>
            <Divider />
            <SessionTimeline
              sessions={sessions} 
              onSessionSelect={handleSessionSelect} 
              activeSession={currentSession} 
              shouldGlow={activeHubIsRecording}
              hubLoadComplete={hubLoadComplete}
            />
          </Card>
        </Col>
        <Col xs={24} lg={18}>
          <Card className="chart-card" style={{ marginBottom: '16px', position: 'relative' }}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button 
                icon={<MoreOutlined />}
                style={{ 
                  position: 'absolute', 
                  top: 350, 
                  right: 0,
                  background: '#f0f0f0',
                  borderRadius: '8px',
                  width: '32px', 
                  height: '32px', 
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0 2px 6px #f0f0f0', 
                  color: '#595959'
                }}
              />
            </Dropdown>
            <ResponsiveLineChart
              data={chartData}
              isLoading={isLoading}
              renamedKeys={renamedKeys}
              sensorConfig={currentSessionConfig}
              onConfigUpdate={handleConfigUpdate}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;