import React, { useState } from 'react';
import { Input, Row, Col, Modal, Divider } from 'antd';

const SensorManager = ({ activeSensorInfo = {}, onSetSensorPrefs }) => {
  const [temporaryValues, setTemporaryValues] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleIntervalClick = (sensorKey, field) => {
    setCurrentField(field);
    setInputValue(activeSensorInfo[sensorKey]?.[field] / 1000 || '');
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    const sanitizedValue = parseFloat(inputValue);
    if (!isNaN(sanitizedValue)) {
      const updatedValue = sanitizedValue * 1000;
      let updatedConfig = { ...activeSensorInfo };
      
      // Update all sensors to the same interval
      Object.keys(updatedConfig)
        .filter(key => key?.startsWith('sensor'))
        .forEach(key => {
          updatedConfig[key] = {
            ...updatedConfig[key],
            [currentField]: updatedValue
          };
        });

      setTemporaryValues(prev => {
        const newValues = { ...prev };
        Object.keys(updatedConfig)
          .filter(key => key?.startsWith('sensor'))
          .forEach(key => {
            newValues[key] = {
              ...newValues[key],
              [currentField]: updatedValue
            };
          });
        return newValues;
      });

      onSetSensorPrefs?.(updatedConfig);
      setIsModalVisible(false);
    } else {
      alert("Invalid input: not a number");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  // Count unique sensors with null safety
  const sensorCounts = Object.entries(activeSensorInfo || {})
    .filter(([key]) => key?.startsWith('sensor'))
    .reduce((acc, [_, sensor]) => {
      if (!sensor?.device || !sensor?.measurement) return acc;
      const key = `${sensor.device}-${sensor.measurement}`;
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

  // Get first instance of each unique sensor type with null safety
  const uniqueSensors = Object.entries(activeSensorInfo || {})
    .filter(([key]) => key?.startsWith('sensor'))
    .reduce((acc, [key, sensor]) => {
      if (!sensor?.device || !sensor?.measurement) return acc;
      const sensorKey = `${sensor.device}-${sensor.measurement}`;
      if (!acc[sensorKey]) {
        acc[sensorKey] = { ...sensor, key };
      }
      return acc;
    }, {});

  const batteryInfo = activeSensorInfo?.battery_info;

  return (
    <div className="space-y-6">
      <div>
        <h2 className="welcome-heading">Sensors</h2>
        {activeSensorInfo && Object.keys(activeSensorInfo).length > 0 && activeSensorInfo?.device ? (
          <>
            <Row gutter={24} className="mb-2">
              <Col xs={9} lg={9}><h4>Type</h4></Col>
              <Col xs={8} lg={8}><h4>Count</h4></Col>
              <Col xs={7} lg={7}><h4>Interval</h4></Col>
            </Row>
            {Object.values(uniqueSensors).map((sensor) => (
              <Row key={sensor.key} gutter={24} className="mb-2">
                <Col xs={9} lg={9}>{sensor.measurement}</Col>
                <Col xs={8} lg={8}>
                  {sensorCounts[`${sensor.device}-${sensor.measurement}`]}
                </Col>
                <Col xs={7} lg={7} 
                  onClick={() => handleIntervalClick(sensor.key, 'poll_interval')}
                  className="cursor-pointer text-blue-500">
                  {(temporaryValues[sensor.key]?.poll_interval || sensor.poll_interval) / 1000} second
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <p>No sensors available in range of this hub...</p>
        )}
      </div>

      <Divider />

      {batteryInfo && (
        <div>
          <h2 className="welcome-heading">Battery Status</h2>
            <Row gutter={[24, 16]}>
              <Col xs={8} lg={8}>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-gray-500">Power Status</h4>
                    <p className="text-lg">{batteryInfo.power_status}</p>
                  </div>
                  <div>
                    <h4 className="text-gray-500">State of Charge</h4>
                    <p className="text-lg">{batteryInfo.soc}{batteryInfo.soc_unit}</p>
                  </div>
                </div>
              </Col>
              
              <Col xs={8} lg={8}>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-gray-500">Voltage</h4>
                    <p className="text-lg">
                      {batteryInfo.voltage} {batteryInfo.voltage_unit}
                    </p>
                  </div>
                  <div>
                    <h4 className="text-gray-500">Current</h4>
                    <p className="text-lg">
                      {batteryInfo.current} {batteryInfo.current_unit}
                    </p>
                  </div>
                </div>
              </Col>

              <Col xs={8} lg={8}>
                <div className="space-y-4">
                  <div>
                    <h4 className="text-gray-500">Battery Health</h4>
                    <p className="text-lg">{batteryInfo.soh}{batteryInfo.soh_unit}</p>
                  </div>
                  <div>
                    <h4 className="text-gray-500">Capacity</h4>
                    <p className="text-lg">
                      {batteryInfo.capacity?.remaining}/{batteryInfo.capacity?.full} {batteryInfo.capacity?.unit}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
        </div>
      )}

      <Modal
        title="Edit Interval"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleModalOk}
        />
      </Modal>
    </div>
  );
};

export default SensorManager;